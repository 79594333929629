var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tab-item',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-10 mt-0",attrs:{"small":"","color":"primary","fab":"","dark":"","absolute":"","top":"","right":""},on:{"click":_vm.toogleRegisterbankDilog}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Configurar novo banco")])]),_c('v-card-text',{staticClass:"px-15 mt-5"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.banks,"items-per-page":5,"no-data-text":"Nenhum banco configurado"},scopedSlots:_vm._u([{key:"item.title_config.numeroCarteira",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title_config ? item.title_config.numeroCarteira : "-")+" ")]}},{key:"item.title_config.orgaoNegativador",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title_config ? item.title_config.orgaoNegativador : "-")+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btMicro px-1 mr-1",attrs:{"x-small":"","color":"primary","dark":""},on:{"click":function($event){return _vm.editConfigBank(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","x-small":""}},[_vm._v(" edit ")])],1)]}}],null,true)},[_c('span',{staticClass:"miniTtip"},[_vm._v("Editar configuração")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btMicro px-1",attrs:{"x-small":"","color":"red","dark":""},on:{"click":function($event){return _vm.confirmDelConfig(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","x-small":""}},[_vm._v(" mdi-delete-forever ")])],1)]}}],null,true)},[_c('span',{staticClass:"miniTtip"},[_vm._v("Excluir configuração")])])]}}])})],1),_c('RegisterBank',{attrs:{"adm":true,"verifySteps":_vm.verifySteps,"getConfiguredBanks":_vm.getConfiguredBanks,"editConfigData":_vm.editConfigData,"registerbankDilog":_vm.registerbankDilog,"toogleRegisterbankDilog":_vm.toogleRegisterbankDilog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }