<template>
    <v-form ref="searchTransectionsFrm">
        <!-- <v-container class="px-0 mb-2 mx-15 px-5"> -->
        <v-row class="px-16 mx-1 pt-7 pb-8">
            <!-- <v-col cols="2" class="pl-0 pr-3"> -->
            <v-menu v-model="start_dateMenu" :close-on-content-click="false" transition="scale-transition" offset-y
                min-width="290px">
                <template v-slot:activator="{ on }">
                    <v-text-field class="mr-5" clearable v-model="start_dateFormated" label="Data inicial" readonly
                        v-on="on"></v-text-field>
                </template>
                <v-date-picker no-title v-model="start_date" @input="start_dateMenu = false"></v-date-picker>
            </v-menu>
            <!-- </v-col> -->
            <!-- <v-col cols="2" class="pl-2 pr-2"> -->
            <v-menu v-model="end_dateMenu" :close-on-content-click="false" transition="scale-transition" offset-y
                min-width="290px">
                <template v-slot:activator="{ on }">
                    <v-text-field class="mr-5" clearable v-model="end_dateFormated" label="Data final" readonly
                        v-on="on"></v-text-field>
                </template>
                <v-date-picker no-title v-model="end_date" @input="end_dateMenu = false"></v-date-picker>
            </v-menu>
            <!-- </v-col> -->
            <!-- <v-col cols="12" md="2"> -->
            <v-text-field class="mr-5" v-model="merchant_order" label="Número do pedido" required></v-text-field>
            <!-- </v-col> -->


            <!-- <v-col cols="12" md="3"> -->
            <v-text-field class="mr-5" v-model="transaction_code" label="Código de transação" required></v-text-field>
            <!-- </v-col> -->


            <!-- <v-col class="d-flex" cols="12" sm="3"> -->
            <v-select style="max-width: 280px;" class="mr-5" clearable :items="transectionStatus" v-model="status"
                label="Status"></v-select>
            <!-- </v-col> -->
            <!-- <v-col class="py-0" cols="1"> -->
            <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="sendForm()" v-bind="attrs" v-on="on" class="mx-2" fab dark small color="primary">
                        <v-icon dark small> search </v-icon>
                    </v-btn>
                </template>
                <span>Procurar</span>
            </v-tooltip>
            <!-- </v-col> -->
        </v-row>
        <!-- </v-container> -->
    </v-form>
</template>

<script>
//   "start_date":"",
// 	"end_date":"",
// 	"status":"Confirmado",
// 	"merchant_order":"",         (Label para esse campo 'Pedido nro.')
// 	"app":"cielo"
import * as moment from "moment";
export default {
    computed: {
        start_dateFormated: {
            get: function () {
                let date = moment(this.start_date).format("DD/MM/YYYY");
                return date == "Invalid date" ? null : date;
            },
            // setter
            set: function (newValue) {
                if (!newValue) {
                    this.start_date = "";
                }
                let date = moment(this.start_date).format("DD/MM/YYYY");
                if (date == "Invalid date") {
                    return null;
                }
            },
        },
        end_dateFormated: {
            get: function () {
                let date = moment(this.end_date).format("DD/MM/YYYY");
                return date == "Invalid date" ? null : date;
            },
            // setter
            set: function (newValue) {
                if (!newValue) {
                    this.end_date = "";
                }
                let date = moment(this.end_date).format("DD/MM/YYYY");
                if (date == "Invalid date") {
                    return null;
                }
            },
        },
    },
    watch: {
        creditCardTransectionsDialog(v) {
            if (!v) {
                this.reseteForm()
            }
        }
    },
    methods: {
        reseteForm() {
            this.start_date = ""
            this.end_date = ""
            this.merchant_order = ""
            this.transaction_code = ""
            this.status = ""
        },
        sendForm() {
            let data = {
                start_date: this.start_date,
                end_date: this.end_date,
                merchant_order: this.merchant_order,
                transaction_code: this.transaction_code,
                status: this.status,
            }
            this.getTransections(data)
        }
    },
    data() {
        return {
            transectionStatus: ["Não finalizado", "Autorizado", "Confirmado", "Não autorizado", "Cancelado", "Falha", "Aguardando retorno"],
            start_date: "",
            start_dateMenu: false,
            end_date: "",
            end_dateMenu: false,
            merchant_order: "",
            transaction_code: "",
            status: "",
        }
    },

    props: {
        getTransections: Function,
        creditCardTransectionsDialog: Boolean
    },
}
</script>

<style lang="scss" scoped></style>