<template>
  <v-form v-model="valid" class="mb-8 mt-7 mx-16">
    <v-row>
      <v-col v-show="false" style="max-width: 175px" cols="12" sm="2"><v-select readonly v-model="selectedModule"
          :items="modules" label="Modúlo"></v-select>
      </v-col>
      <v-col v-show="false" style="max-width: 310px" cols="12" sm="3"><v-select clearable v-model="selectedSubModules"
          :items="subModules" label="Áreas do sistema"></v-select>
      </v-col>
      <v-col v-show="false" cols="12" sm="2">
        <v-combobox clearable hint="Digite o nome do usuário" persistent-hint v-model="selectedUser" :items="usersList"
          label="Usuários"></v-combobox>
        <!-- <v-select :items="usersList" label="Usuários"></v-select> -->
      </v-col>
      <!-- <v-col cols="12" sm="3">
        <v-text-field class="" v-model="sacado" label="Nome do sacado"></v-text-field>
      </v-col> -->
      <v-col cols="12" sm="3">
        <v-text-field class="" v-model="transaction_code" label="Código da transação"></v-text-field>
      </v-col>
      <v-col cols="12" sm="2"><v-select clearable v-model="selectedLogType" :items="logTypes"
          label="Tipo de log"></v-select>
      </v-col>
      <v-col cols="12" sm="3">
        <v-menu ref="calendar" v-model="calendar" :close-on-content-click="false" transition="scale-transition"
          max-width="290px" min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field clearable class="rangeInputDate" v-model="dateRangeText" label="Período"
              hint="Selecione o primeiro e o último dia" persistent-hint v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-date-picker class="rangeMode" range v-model="dates" no-title>
            <div style="width: 100%" class="bordTop d-flex flex-row justify-start">
              <v-btn class="mt-2" text color="primary" @click="calendar = false">
                OK
              </v-btn>
              <v-btn class="mt-2" text color="primary" @click="calendar = false">
                Cancelar
              </v-btn>
            </div>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col style="max-width: 70px;" cols="12" sm="1" class="mt-4">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mx-2" fab dark x-small color="primary" v-bind="attrs" v-on="on" @click="listLogsByFilters">
              <v-icon dark small> search </v-icon>
            </v-btn>
          </template>
          <span>Filtrar</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  data() {
    return {
      // sacado: "",
      transaction_code: "",
      dates: [],
      calendar: false,
      valid: true,
      selectedUser: "",
      selectedModule: "",
      selectedSubModules: "",
      selectedLogType: "",
      modules: [],
      subModules: [],
      usersList: [],
      logTypes: [],
    };
  },
  watch: {
    perPage(v) {
      this.listLogsByFilters()
    },
    billetsLogDilog(v) {
      if (!v) {
        // this.sacado = ""
        this.transaction_code = ""
        this.selectedLogType = ""
        this.dates = []
      }
    }

  },
  computed: {
    dateRangeText: {
      // getter
      get: function () {
        return this.formatRangeDate(this.dates);
      },
      // setter
      set: function (newValue) {
        if (!newValue) {
          this.dates = [];
        }
      },
    },
    userName() {
      return localStorage.getItem("userName")
    },
    // dateRangeText() {
    //   //console.log("ppppp", this.dates);
    //   return this.formatRangeDate(this.dates);
    // },
  },
  mounted() {
    this.getModules();
    this.getSubModules();
    this.getUsers();
    this.getLogTypes();
  },
  methods: {
    listLogsByFilters() {
      let query = {
        module: "Administrativo",
        sub_module: "Cartão Crédito",
        // sacado: this.sacado,
        transaction_code: this.transaction_code,
        type: this.selectedLogType,
        start_date: this.dates[0],
        end_date: this.dates[1],
        user_name: this.userName,
        per_page: this.perPage,
        // "module": "Administrativo", "sub_module": "Boletos", "type": "", "user_name": "Criarenet", "per_page": 10
      };

      this.getLogs(query, 0);
    },
    formatRangeDate(listDates) {
      let returnDate = "";
      for (let i = 0; i < listDates.length; i++) {
        let [year, month, day] = listDates[i].split("-");
        returnDate += `${day}/${month}/${year}${i == 0 ? " - " : ""}`;
      }

      return returnDate;
    },
    getModules() {
      var obj = {
        url: "/api/auditlogs/get-modules",
        query: null,
        method: "get",
      };
      //console.log(obj);
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        // console.log(response);
        this.modules = [...response.data.map((mod) => mod.module)];
        this.selectedModule = this.modules[0];
      });
    },
    getSubModules() {
      var obj = {
        url: "/api/auditlogs/get-submodules",
        query: null,
        method: "get",
      };
      //console.log(obj);
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        // console.log(response);
        this.subModules = [...response.data.map((subMod) => subMod.sub_module)];
      });
    },
    getUsers() {
      var obj = {
        url: "/api/auditlogs/get-users",
        query: null,
        method: "get",
      };
      //console.log(obj);
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        // console.log(response);
        this.usersList = [
          ...response.data
            .filter((user) => {
              if (user.user) return user.user;
            })
            .map((us) => us.user),
        ];
      });
    },
    getLogTypes() {
      var obj = {
        url: "/api/auditlogs/get-types",
        query: null,
        method: "get",
      };
      //console.log(obj);
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        // console.log(response);
        this.logTypes = [...response.data.map((log) => log.type)];
      });
    },
  },
  props: { getLogs: Function, perPage: Number, billetsLogDilog: Boolean },
};
</script>

<style>
.rangeInputDate input {
  font-size: 14px !important;
}

.rangeMode .v-date-picker-table {
  max-height: 210px;
}
</style>