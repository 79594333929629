<template>
    <v-tab-item>
        <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
                <v-btn small @click="toogleRegisterbankDilog" v-bind="attrs" v-on="on" class="mr-10 mt-0"
                    color="primary" fab dark absolute top right>
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </template>
            <span>Configurar novo banco</span>
        </v-tooltip>
        <v-card-text class="px-15 mt-5">
            <v-data-table :headers="headers" :items="banks" :items-per-page="5" class="elevation-0"
                no-data-text="Nenhum banco configurado">
                <template v-slot:item.title_config.numeroCarteira="{ item }">
                    {{ item.title_config ? item.title_config.numeroCarteira : "-" }}
                </template>
                <template v-slot:item.title_config.orgaoNegativador="{ item }">
                    {{ item.title_config ? item.title_config.orgaoNegativador : "-" }}
                </template>

                <template v-slot:item.actions="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" class="btMicro px-1 mr-1" @click="editConfigBank(item)"
                                x-small color="primary" dark><v-icon center x-small> edit </v-icon></v-btn>
                        </template>
                        <span class="miniTtip">Editar configuração</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" class="btMicro px-1" @click="confirmDelConfig(item)" x-small
                                color="red" dark><v-icon center x-small> mdi-delete-forever
                                </v-icon></v-btn>
                        </template>
                        <span class="miniTtip">Excluir configuração</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-card-text>
        <RegisterBank :adm="true" :verifySteps="verifySteps" :getConfiguredBanks="getConfiguredBanks"
            :editConfigData="editConfigData" :registerbankDilog="registerbankDilog"
            :toogleRegisterbankDilog="toogleRegisterbankDilog" />
    </v-tab-item>
</template>

<script>
import RegisterBank from "../clients/billets/banks/RegisterBank.vue";
export default {
    watch: {
        banksListDialog(v) {
            if (v && this.stepsOn == 1) {
                this.toogleBanksListDialog();
                this.toogleRegisterbankDilog();
            }
        },
    },
    methods: {
        getConfiguredBanks(loader) {
            var obj = {
                // noLoader: loader,
                url: "/api/v1/title/user/bank/getUserBank",
                query: null,
                method: "get",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                // this.banksProfileRegisterCbo = [
                //     ...response.data.map((obj) => {
                //         return {
                //             number_bank: obj.bank.number_bank,
                //             pg_bank_favoured_id: obj.id,
                //             bank_name: obj.bank.bank_name,
                //         };
                //     }),
                // ];
                this.banks = [...response.data];
            });
        },
        editConfigBank(data) {
            console.log('numeration')
            let obj = {
                id: data.id,
                bank: { number_bank: data.bank.number_bank, id: data.bank.id, bank_name: data.bank.bank_name },
                numeroConvenio: data.title_config.numeroConvenio,
                numeroCarteira: data.title_config.numeroCarteira,
                orgaoNegativador: data.title_config.orgaoNegativador,
                numeroVariacaoCarteira: data.title_config.numeroVariacaoCarteira,
                agencia: data.title_config.agencia,
                contaCorrente: data.title_config.contaCorrente,
                credentials: data.credentials,
                numeration: data.numeration ? data.numeration : {},
                algoritmoNumeracao: data.title_config.algoritmoNumeracao,
                numeracaoInicial: data.title_config.numeracaoInicial,
                tarifaLiquidado: data.title_config.tarifaLiquidado,
                tarifaRegistro: data.title_config.tarifaRegistro,
                tarifaBaixa: data.title_config.tarifaBaixa,
                // this.editConfigData.algoritmoNumeracao = this.editConfigData.title_config.algoritmoNumeracao
                callback_url: data.callback_url
            };

            this.editConfigData = { ...obj };
            this.toogleRegisterbankDilog();
        },
        confirmDelConfig(data) {
            let obj = {
                message: `Você deseja excluir a configuração do banco <b>${data.bank.bank_name}</b>?`,
                title: "Excluir",
                callback: (_) => {
                    this.delConfig(data);
                },
                type: "warning",
                btConfirm: { name: "Sim", show: true },
                btCancel: { name: "Não", show: true },
            };
            this.$store.commit("confirmMessage/showConfirmMessage", obj);
        },
        delConfig(data) {
            // "partner_id":68,
            //   "contract_id":129,
            //   "service_id" : 3,
            //   "id":5
            let obj = {
                url: "/api/v1/title/user/bank/deleteUserBank",
                query: {
                    id: data.id,
                    service_id: 3,
                    partner_id: localStorage.getItem("partner_id"),
                },
                method: "post",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                // let index = this.payment.findIndex((payment) => payment.id === data.id);
                // this.payment.splice(index, 1);
                let opts = {
                    message: `Configuração removida com sucesso.`,
                    type: "success",
                };
                this.$store.commit("showAlert", opts);
                this.getConfiguredBanks();
            });
        },
        toogleRegisterbankDilog() {
            this.registerbankDilog = !this.registerbankDilog;
            if (!this.registerbankDilog) {
                this.editConfigData = null;
            }
        },
    },
    data() {
        return {
            banks: [],
            editConfigData: null,
            registerbankDilog: false,
            headers: [
                {
                    text: "Banco",
                    value: "bank.bank_name",
                    align: "left",
                    sortable: false,
                },
                {
                    text: "Número do convenio",
                    value: "title_config.numeroConvenio",
                    align: "center",
                    sortable: false,
                },
                {
                    text: "Número da carteira",
                    value: "title_config.numeroCarteira",
                    align: "center",
                    sortable: false,
                },
                {
                    text: "Orgao negativador",
                    value: "title_config.orgaoNegativador",
                    align: "center",
                    sortable: false,
                },
                {
                    text: "Ações",
                    value: "actions",
                    align: "center",
                    sortable: false,
                },
            ],
        };
    },
    mounted() {
        this.getConfiguredBanks();
    },
    props: {
        verifySteps: Function,
        stepsOn: Number,
        // banks: Array,
        banksListDialog: Boolean,
        // getConfiguredBanks: Function,
        toogleBanksListDialog: Function,
    },
    computed: {
        responsiveSize() {
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return 220;
                case "sm":
                    return 400;
                case "md":
                    return 500;
                case "lg":
                    return 600;
                case "xl":
                    return 800;
            }
        },
    },
    components: { RegisterBank },
};
</script>

<style></style>