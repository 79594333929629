<template>
    <div>
        <div class="px-8">
            <Filters :profilesCbos="profilesCbos" :banks="banksProfileRegisterCbo" :moreFilters="moreFilters"
                :getBillets="getBillets" />
            <!-- <ToolBar :toogleStatusBatchDialog="toogleStatusBatchDialog" :toogleImportBatchDilog="toogleImportBatchDilog"
            :toogleRegisterBilletDilog="toogleRegisterBilletDilog" :selectedsBillets="selectedsBillets" /> -->
        </div>
        <v-card-text class="px-15 mt-5">
            <v-data-table v-model="selectedsBillets" :headers="headers" :items="billets"
                class="newPagination elevation-0 scrollTable"
                no-data-text="Utilize os filtros acima para listar boletos">
                <template v-slot:item.dataEmissao="{ item }">
                    {{ item.dataEmissao | formatDate }}
                </template>
                <template v-slot:item.dataVencimento="{ item }">
                    {{ item.dataVencimento | formatDate }}
                </template>
                <template v-slot:item.status="{ item }">
                    <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon @click="
                                setStatusBillet(item.status)[2].indexOf('Falha') < 0
                                    ? null
                                    : confirmRetryRegisterBillet(item, $event)
                                " :color="setStatusBillet(item.status)[0]" :class="setStatusBillet(item.status)[3]"
                                center v-bind="attrs" v-on="on">
                                {{ setStatusBillet(item.status)[1] }}
                            </v-icon>
                        </template>
                        <span class="miniTtip" v-html="setStatusBillet(item.status)[2]"></span>
                    </v-tooltip>
                </template>
                <template v-slot:item.data_title.pagador.nome="{ item }">
                    {{ item.data_title.pagador.nome || "Não identificado" }}
                </template>
                <template v-slot:item.value="{ item }">
                    R$ {{ item.value | formatPrice }}
                </template>
                <template v-slot:footer.page-text>
                    <v-row class="d-flex justify-end">
                        <v-col class="pb-0" cols="5">
                            <v-container class="d-flex flex-row justify-end max-width pr-0">
                                <v-pagination v-model="actualPage" :length="totalPages"
                                    :total-visible="8"></v-pagination>
                            </v-container>
                        </v-col>
                        <v-col style="max-width: 130px;" class="pt-9 px-0 pb-0" cols="2"><span class="caption">Itens por
                                página</span></v-col>
                        <v-col style="max-width: 90px;" class="pb-0" cols="2">
                            <v-select v-model="perPage" :items="[2, 5, 10, 50, 100]"></v-select>
                        </v-col>
                    </v-row>
                </template>
            </v-data-table>
        </v-card-text>
    </div>
</template>

<script>
import Filters from "../clients/billets/managerBillets/Filters.vue";
//import ToolBar from "../clients/billets/managerBillets/ToolBar.vue";
//import RegisterBillet from "./RegisterBillet.vue";
// import ImportBatchDialog from "./ImportBatchDialog.vue";
// import ListBatchs from "./ListBatchs.vue";
import MyFilters from "../../filters/genericFilters";
export default {
    destroyed() {
        clearInterval(this.updateStatusBillets);
    },
    watch: {
        perPage(v) {
            this.getBillets(this.$store.state.lastBilletsParans, false, 1)
        },
        actualPage(v, oldV) {
            //console.log("qsqsqs", oldV);
            if (!oldV || !v) {
                return;
            }
            this.getBillets(this.$store.state.lastBilletsParans, false, v);
        },
        managerBilletsDialog(v) {
            this.autoUpdateStatusBillet(v);
            if (v) {
                if (this.stepsOn < 4) {
                    setTimeout(() => {
                        this.toogleRegisterBilletDilog();
                    }, 100);
                }
            }
        },
    },
    methods: {
        getBillets(parans, loader, page) {
            let complementPag = page ? `/?page=${page}` : "";
            this.actualPage = page;
            var obj = {
                noLoader: loader,
                url: "/api/v1/title/get-titles" + complementPag,
                query: { ...parans },
                method: "post",
            };
            obj.query.per_page = this.perPage;
            this.$store.commit("updateLastBilletsParans", obj.query);
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                console.log('biletando')
                this.billets = [];
                let formatBillets = [];
                formatBillets = [
                    ...response.data.data.map((billet) => {
                        let payer = { ...billet.data_title.pagador };
                        let data = { ...billet.data_title };

                        // delete billet.data_title;
                        // delete billet.desconto;
                        // delete billet.desconto;

                        delete data.desconto;
                        delete data.jurosMora;
                        delete data.multa;
                        delete data.pagador;
                        let obj = { ...billet, ...data, ...payer };
                        // console.log("montado",obj)
                        return obj;
                    }),
                ];
                this.totalPages = response.data.last_page;
                this.actualPage = response.data.current_page;
                this.billets = [...formatBillets];
            });
        },
        openBillet(data) {
            let obj = {
                message: `O boleto foi criado com sucesso, deseja fazer download?`,
                title: "Salvar boleto",
                callback: (_) => {
                    window.open(data.title_url);
                },
                type: "warning",
                btConfirm: { name: "Sim", show: true },
                btCancel: { name: "Não", show: true },
            };
            this.$store.commit("confirmMessage/showConfirmMessage", obj);

        },
        confirmRetryRegisterBillet(data, bt) {
            //console.log('0000',bt.target)
            bt.target.blur();
            let obj = {
                message: `Você deseja registrar novamente o boleto selecionado?`,
                title: "Tentar novamente",
                callback: (_) => {
                    this.retryRegisterBillet(data.id);
                },
                type: "warning",
                btConfirm: { name: "Sim", show: true },
                btCancel: { name: "Não", show: true },
            };
            // console.log("boleto",data)
            this.$store.commit("confirmMessage/showConfirmMessage", obj);
        },
        retryRegisterBillet(id) {
            //console.log(id);
            let obj = {
                url: "/api/v1/title/failOver",
                query: {
                    id: id,
                },
                method: "post",
            };

            this.$store.dispatch("request/doRequest", obj).then((response) => {
                if (!response) {
                    return;
                }
                let opts = {
                    message: `Nova tentativa de registro enviada com sucesso.`,
                    type: "success",
                };
                this.$store.commit("showAlert", opts);
                this.getBillets(this.$store.state.lastBilletsParans, false, this.actualPage);
            });
        },
        toogleStatusBatchDialog() {
            //console.log("Status Batch");
            this.listBatchsDialog = !this.listBatchsDialog;
        },
        updateBilletsBar(val) {
            val++
            setTimeout(() => {
                this.updateBilletsBar(val)
            }, 1000);
            return val
        },
        autoUpdateStatusBillet(v) {
            if (v) {
                this.updateStatusBillets = setInterval(() => {
                    this.getBillets(this.$store.state.lastBilletsParans, true, this.actualPage);
                }, 60000);
            } else {
                clearInterval(this.updateStatusBillets);
            }
        },
        // profilesCbos: Array,
        toogleImportBatchDilog() {
            //console.log("batchj");
            this.importBatchDialog = !this.importBatchDialog;
        },
        toogleRegisterBilletDilog() {
            this.registerBilletDilog = !this.registerBilletDilog;
            this.editDataBillet = null;
        },
        confirmCancelBillet(data) {
            let obj = {
                message: `Você deseja cancelar o boleto selecionado?`,
                title: "Cancelar",
                callback: (_) => {
                    this.cancelBillet(data);
                },
                type: "warning",
                btConfirm: { name: "Sim", show: true },
                btCancel: { name: "Não", show: true },
            };
            // console.log("boleto",data)
            this.$store.commit("confirmMessage/showConfirmMessage", obj);
        },
        cancelBillet(data) {
            let obj = {
                url: "/api/v1/title/amortize",
                query: {
                    title_number: data.title_number,
                },
                method: "post",
            };

            this.$store.dispatch("request/doRequest", obj).then((response) => {
                if (!response) {
                    return;
                }
                let opts = {
                    message: `Boleto enviado para cancelamento com sucesso.`,
                    type: "success",
                };
                this.$store.commit("showAlert", opts);
                setTimeout(() => {
                    document.getElementsByTagName("td")[0].click();
                }, 10);
                this.getBillets(this.$store.state.lastBilletsParans, false, this.actualPage);
                // this.updateBilletLine(data.id, "Baixando/Cancelando");
            });
        },

        setStatusBillet(data) {
            //console.log("fff");
            let status = {
                "Baixado/Cancelado": [
                    "grey darken-1",
                    "block",
                    "Boleto cancelado",
                    "justIcon",
                ],
                "Baixando/Cancelando": [
                    "grey darken-1",
                    "warning",
                    "Aguardando cancelamento",
                    "justIcon",
                ],
                Andamento: [
                    "orange darken-2",
                    "query_builder",
                    "Aguardando registro",
                    "justIcon",
                ],
                Aberto: ["blue darken-1", "verified", "Boleto registrado", "justIcon"],
                Liquidado: [
                    "green darken-3",
                    "attach_money",
                    "Boleto liquidado",
                    "justIcon",
                ],
                "Falha no Registro": [
                    "red darken-4",
                    "error",
                    "Falha ao tentar registrar o boleto <br/> Clique aqui para tentar registrar novamente.",
                    "iconButton",
                ],
                "Falha na Baixa/Cancelamento": [
                    "red darken-4",
                    "error",
                    "Falha no cancelamento",
                    "iconButton",
                ],
                Vencido: ["red lighten-1", "event_busy", "Boleto vencido", "justIcon"],
            };
            return status[data];
        },
        editBillet(data) {
            // console.log("eeee",data)
            this.editDataBillet = { ...data };
            this.registerBilletDilog = true;
        },
        setHeigtScrollTable() {
            let pag = document.documentElement.clientHeight;
            let sizeTable = pag - 380;
            document.getElementsByClassName(
                "v-data-table__wrapper"
            )[0].style.height = `${sizeTable}px`;
        },
    },
    mounted() {
        console.log('monta')
        let query = {
            number_bank: "",
            start_date: "",
            end_date: "",
            dataVencimento: "",
            numeroInscricao: "",
            email: "",
            profile_id: "",
            status: "",
            batch: "",
        };
        this.getBillets(query, true, this.actualPage);
    },
    data() {
        return {
            setedPage: false,
            totalPages: 0,
            perPage: 2,
            actualPage: 0,
            billets: [],
            updateStatusBillets: null,
            listBatchsDialog: false,
            importBatchDialog: false,
            editDataBillet: null,
            registerBilletDilog: false,
            moreFilters: false,
            selectedsBillets: [],
            // billets: [],
            headers: [
                {
                    text: "Status",
                    value: "status",
                    sortable: false,
                    sortable: true,
                },
                { text: "Sacado", value: "data_title.pagador.nome", sortable: true },
                {
                    text: "Emissão",
                    value: "dataEmissao",
                    sortable: false,
                },
                {
                    text: "Vencimento",
                    sortable: true,
                    value: "dataVencimento",
                },
                { text: "Valor", value: "value", sortable: false, align: "right" },
                // {
                //   text: "Valor com desconto",
                //   value: "valorAbatimento",
                //   sortable: false,
                // },
                // { text: "Ações", value: "actions", sortable: false, align: "center" },
            ],
        };
    },
    mounted() { },
    props: {
        updateBilletLine: Function,
        verifySteps: Function,
        stepsOn: Number,
        //getBillets: Function,
        //billets: Array,
        profilesCbos: Array,
        banksProfileRegisterCbo: Array,
        managerBilletsDialog: Boolean,
        toogleManagerBilletsDialog: Function,
    },
    components: {
        Filters,
        //ToolBar,
        //RegisterBillet,
        // ImportBatchDialog,
        // ListBatchs,
    },
};
</script>

<style>
.statusButton {
    min-width: 24px !important;
}

.iconButton {
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    border-radius: 50%;
}

.justIcon {
    cursor: auto !important;
}

/* .scrollTable .v-data-table__wrapper {
  overflow-y: auto !important;
} */
.v-btn.v-btn--disabled.btMicro.disableBt {
    background-color: #00796b !important;
    border-color: #00796b !important;
    opacity: 0.4 !important;
}
</style>