<template>
  <v-dialog persistent v-model="registerProfileDilog" scrollable max-width="1280px">
    <v-card>
      <v-card-title style="border-bottom: 1px solid #ccc;" class="px-2 pb-5"><v-app-bar height="50" flat
          color="rgba(0, 0, 0, 0)">
          <v-toolbar-title class="text-h5 pl-0 pt-0">
            {{ false ? "Editar perfil" : "Novo perfil" }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="toogleRegisterProfileDilog" class="mt-7" color="red" fab icon dark x-small absolute top right>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>


      </v-card-title>
      <v-tabs class="px-6" v-model="tab" color="primaryNew">
        <v-tab :key="0">Dados do perfil</v-tab>
        <v-tab :key="1">Descontos</v-tab>
        <v-tab :key="2">Multas</v-tab>
        <v-tab :key="3">Juros</v-tab>
        <v-tab :key="4">Template de e-mail</v-tab>
      </v-tabs>
      <v-card-text>
        <v-form ref="profileForm" style="width: 100%">
          <v-tabs-items class="pt-5" style="min-height: 350px; border-top: 1px solid #ccc" v-model="tab">
            <v-tab-item class="tabValidatorProfile" :key="0">
              <v-row class="px-3 py-0">
                <v-col class="pl-5" cols="5">
                  <v-text-field class="requiredField" :rules="$store.state.formRules.required"
                    v-model="formData.profile_name" label="Nome do perfil"></v-text-field>
                </v-col>
                <v-col class="pl-5" cols="4">
                  <v-select return-object class="requiredField" :rules="$store.state.formRules.required" clearable
                    v-model="formData.objBank" :items="$store.state.banksProfileRegisterCbo" label="Selecione um banco"
                    item-text="bank_name" item-value="pg_bank_favoured_id"></v-select>
                </v-col>
                <v-col class="pl-5" cols="3">
                  <v-select class="c" clearable v-model="formData.profile_data.codigoModalidade" :items="modalidades"
                    label="Selecione a modalidade" item-text="name" item-value="id"></v-select>
                </v-col>

                <v-col class="pl-5" cols="4">
                  <v-select hint="Define se o boleto terá um QRCode Pix atrelado" persistent-hint class="" clearable
                    v-model="formData.profile_data.indicadorPix" :items="utilizaPix" label="Utilizar Pix"
                    item-text="name" item-value="id"></v-select>
                </v-col>
                <v-col class="pl-5" cols="4">
                  <v-text-field-money class="specialInfo" v-model="formData.profile_data.valorAbatimento"
                    v-bind:label="'Valor de abatimento'" v-bind:properties="{
                      prefix:
                        formData.profile_data.valorAbatimento &&
                          formData.profile_data.valorAbatimento.length
                          ? 'R$'
                          : '',
                      readonly: false,
                      outlined: false,
                      clearable: true,
                      placeholder: ' ',
                    }" v-bind:options="{
                      locale: 'pt-BR',
                      length: 11,
                      precision: 2,
                      empty: null,
                    }" />
                </v-col>
                <v-col class="pl-5" cols="4">
                  <v-select class="" clearable v-model="formData.profile_data.indicadorPermissaoRecebimentoParcial
                    " :items="aceiteValorParcial" label="Receber valor parcialmente" item-text="name"
                    item-value="id"></v-select>
                </v-col>

                <v-col class="pl-5" cols="4">
                  <v-text-field type="number" clearable class="" v-model="formData.profile_data.quantidadeDiasProtesto"
                    label="Qtd. de dias para protesto"
                    hint="Quantos dias após a data de vencimento do boleto para iniciar o processo de cobrança através de protesto."
                    persistent-hint></v-text-field>
                </v-col>

                <v-col class="pl-5" cols="4">
                  <v-text-field type="number" clearable class=""
                    v-model="formData.profile_data.quantidadeDiasNegativacao" label="Qtd. de dias para negativação"
                    hint="Quantos dias após a data de vencimento do boleto para iniciar o processo de negativação através da opção escolhida no campo orgão negativador."
                    persistent-hint></v-text-field>
                </v-col>

                <v-col class="pl-5" cols="4">
                  <v-select class="" clearable v-model="formData.profile_data.indicadorAceiteTituloVencido"
                    :items="aceiteVencidos" label="Aceitar título vencido" item-text="name" item-value="id"></v-select>
                </v-col>

                <v-col class="pl-5" cols="4">
                  <v-select hint="Identifica se o boleto de cobrança foi aceito (reconhecimento da dívida pelo Pagador)"
                    persistent-hint class="specialInfo" clearable v-model="formData.profile_data.codigoAceite"
                    :items="$store.state.billetsCbos.aceite" label="Definição de aceite" item-text="name"
                    item-value="id"></v-select>
                </v-col>

                <v-col class="pl-5" cols="4">
                  <v-text-field type="number" clearable class=""
                    v-model="formData.profile_data.numeroDiasLimiteRecebimento" label="Qtd. de dias para recebimento"
                    hint="Quantos dias o boleto ficará disponível para pagamento após seu vencimento."
                    persistent-hint></v-text-field>
                </v-col>

                <v-col class="pl-5" cols="4">
                  <v-select class="specialInfo" clearable v-model="formData.profile_data.codigoTipoTitulo"
                    :items="$store.state.billetsCbos.registrationTypes" label="Selecione tipo do título"
                    item-text="name" item-value="id"></v-select>
                </v-col>

                <v-col class="pl-6 pb-0" cols="6">
                  <v-textarea rows="1" type="" class="specialInfo" v-model="formData.profile_data.descricaoTipoTitulo"
                    label="Breve descrição sobre o Tipo do título"></v-textarea>
                </v-col>

                <v-col class="pl-5 pb-0" cols="6">
                  <v-textarea rows="1" type="" class="" v-model="formData.profile_data.campoUtilizacaoBeneficiario"
                    label="Info. adicionais sobre o beneficiário"></v-textarea>
                </v-col>

              </v-row>
            </v-tab-item>
            <v-tab-item class="tabValidatorProfile" :key="1">
              <v-row class="px-3 py-5">
                <v-col class="pl-5 pb-0 pt-4" cols="12">
                </v-col>
                <v-col class="pl-5" cols="6">
                  <v-select class="" clearable v-model="formData.profile_data.desconto.tipo" :items="tipoDesconto"
                    label="Tipo de desconto" item-text="name" item-value="id"></v-select>
                </v-col>
                <v-col cols="6" class="mt-0 pr-5">
                  <v-menu v-model="dataExpiracaoMenu" :close-on-content-click="false" transition="scale-transition"
                    offset-y min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field clearable v-model="dataExpiracaoFormated" label="Data de expiração" readonly
                        hint=" Define uma data de expiração para o desconto." persistent-hint v-on="on"></v-text-field>
                    </template>
                    <v-date-picker no-title v-model="formData.profile_data.desconto.dataExpiracao"
                      @input="dataExpiracaoMenu = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col class="pl-5" cols="6">
                  <v-text-field prefix=" %" type="number" v-model="formData.profile_data.desconto.porcentagem"
                    label="Porcentagem do desconto" />
                  <!-- <v-text-field-money v-model="formData.profile_data.desconto.porcentagem"
                    v-bind:label="'Porcentagem do desconto'" v-bind:properties="{
                      readonly: false,
                      outlined: false,
                      clearable: true,
                      placeholder: ' ',
                    }" v-bind:options="{
                      locale: 'pt-BR',
                      length: 11,
                      precision: 2,
                      empty: null,
                    }" /> -->
                </v-col>
                <v-col cols="6">
                  <v-text-field-money v-model="formData.profile_data.desconto.valor" v-bind:label="'Valor do desconto'"
                    v-bind:properties="{
                      prefix:
                        formData.profile_data.desconto.valor &&
                          formData.profile_data.desconto.valor.length
                          ? 'R$'
                          : '',
                      readonly: false,
                      outlined: false,
                      clearable: true,
                      placeholder: ' ',
                    }" v-bind:options="{
                      locale: 'pt-BR',
                      length: 11,
                      precision: 2,
                      empty: null,
                    }" />
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item class="tabValidatorProfile" :key="2">
              <v-row class="px-3 py-5">
                <v-col class="pl-5 pb-0 pt-0" cols="12">
                </v-col>
                <v-col class="pl-5" cols="6">
                  <v-select class="" clearable v-model="formData.profile_data.multa.tipo" :items="tipoMulta"
                    label="Tipo de multa" item-text="name" item-value="id"></v-select>
                </v-col>
                <!-- <v-col cols="6" class="mt-0 pr-5">
                  <v-menu v-model="dataMenu" :close-on-content-click="false" transition="scale-transition" offset-y
                    min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        clearable v-model="dataFormated" label="Data de cobrança" readonly
                        hint="Deve ser maior que a data de vencimento e menor que a data de validade do boleto."
                        persistent-hint v-on="on"></v-text-field>
                    </template>
                    <v-date-picker no-title v-model="formData.profile_data.multa.data"
                      @input="dataMenu = false"></v-date-picker>
                  </v-menu>
                </v-col> -->
                <v-col class="pl-5" cols="6">
                  <v-text-field prefix=" %" type="number" v-model="formData.profile_data.multa.porcentagem"
                    label="Porcentagem da multa" />
                  <!-- <v-text-field-money v-model="formData.profile_data.multa.porcentagem"
                    v-bind:label="'Porcentagem da multa'" v-bind:properties="{
                      readonly: false,
                      outlined: false,
                      clearable: true,
                      placeholder: ' ',
                    }" v-bind:options="{
                      locale: 'pt-BR',
                      length: 11,
                      precision: 2,
                      empty: null,
                    }" /> -->
                </v-col>
                <v-col cols="6" class="pl-5">
                  <v-text-field-money v-model="formData.profile_data.multa.valor" v-bind:label="'Valor da multa'"
                    v-bind:properties="{
                      prefix:
                        formData.profile_data.multa.valor &&
                          formData.profile_data.multa.valor.length
                          ? 'R$'
                          : '',
                      readonly: false,
                      outlined: false,
                      clearable: true,
                      placeholder: ' ',
                    }" v-bind:options="{
                      locale: 'pt-BR',
                      length: 11,
                      precision: 2,
                      empty: null,
                    }" />
                </v-col>
              </v-row></v-tab-item>
            <v-tab-item class="tabValidatorProfile" :key="3">
              <v-row class="px-3 py-5">
                <v-col class="pl-5 pb-0 pt-0" cols="12">
                </v-col>
                <v-col class="pl-5" cols="6">
                  <v-select class="" clearable v-model="formData.profile_data.jurosMora.tipo" :items="tipoJuros"
                    label="Tipo de juros" item-text="name" item-value="id"></v-select>
                </v-col>
                <v-col class="pl-5" cols="6">
                  <v-text-field prefix=" %" type="number" v-model="formData.profile_data.jurosMora.porcentagem"
                    label="Porcentagem do juros" />
                  <!-- <v-text-field-money v-model="formData.profile_data.jurosMora.porcentagem"
                    v-bind:label="'Porcentagem do juros'" v-bind:properties="{
                      readonly: false,
                      outlined: false,
                      clearable: true,
                      placeholder: ' ',
                    }" v-bind:options="{
                      locale: 'pt-BR',
                      length: 11,
                      precision: 5,
                      empty: null,
                    }" /> -->
                </v-col>
                <v-col cols="6">
                  <v-text-field-money v-model="formData.profile_data.jurosMora.valor" v-bind:label="'Valor do juros'"
                    v-bind:properties="{
                      prefix:
                        formData.profile_data.jurosMora.valor &&
                          formData.profile_data.jurosMora.valor.length
                          ? 'R$'
                          : '',
                      readonly: false,
                      outlined: false,
                      clearable: true,
                      placeholder: ' ',
                    }" v-bind:options="{
                      locale: 'pt-BR',
                      length: 11,
                      precision: 2,
                      empty: null,
                    }" />
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item class="">
              <v-form ref="profileMailForm">
                <v-row class="px-3 pb-5">
                  <v-col class="pb-0" cols="8">
                    <h3 class="mb-2">Configurar um template de e-mail</h3>
                    <p class="mb-1 caption grey--text text--darken-3">
                      Preencha os campos abaixo e envie sua logomarca para criar um template personalizado dos emails
                      que
                      serão enviados para seus clientes.
                    </p>
                    <v-checkbox v-model="disabEmailFields" label="Habilitar e-mail" color="primary"></v-checkbox>
                  </v-col>
                  <v-col cols="6" class="pt-0">
                    <v-text-field :disabled="!disabEmailFields" clearable
                      :class="[disabEmailFields ? 'requiredField' : '']" :rules="$store.state.formRules.required"
                      v-model="formData.mail_template.subject" label="Assunto" hint="Campo para o assunto do e-mail"
                      persistent-hint>
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="pt-0">
                    <v-text-field :disabled="!disabEmailFields" clearable
                      :class="[disabEmailFields ? 'requiredField' : '']" :rules="$store.state.formRules.required"
                      v-model="formData.mail_template.title" label="Título do e-mail"
                      hint="Campo para o título no corpo do e-mail" persistent-hint>
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field :disabled="!disabEmailFields" clearable
                      :class="[disabEmailFields ? 'requiredField' : '']" :rules="$store.state.formRules.required"
                      v-model="formData.mail_template.footer_message" label="Texto do rodapé do e-mail"
                      hint="Campo para o texto no rodapé do corpo do e-mail" persistent-hint>
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <div v-if="imageSrc ? true : false" class="d-flex justify-center wrapImg py-10">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn @click="clearImage" v-bind="attrs" v-on="on" v-show="imageSrc ? true : false" x-small
                            class="closeImg" icon color="error">
                            <v-icon>cancel</v-icon>
                          </v-btn>
                        </template>
                        <span class="miniTtip">Remover imagem</span>
                      </v-tooltip>
                      <v-img v-show="imageSrc ? true : false" contain :src="cover(imageSrc)" max-width="200"
                        max-height="200"></v-img>
                    </div>
                    <!-- @change="uploadCsv" -->
                    <v-file-input :disabled="!disabEmailFields" v-if="!imageSrc ? true : false" @change="uploadImage"
                      :class="[disabEmailFields ? 'requiredField' : '']" :rules="$store.state.formRules.required"
                      v-model="formData.mail_template.logo_image" label="Logotipo"></v-file-input>
                  </v-col>
                  <v-col cols="8">
                    <v-textarea :disabled="!disabEmailFields" rows="1"
                      :class="[disabEmailFields ? 'requiredField' : '', 'pt-1']"
                      :rules="$store.state.formRules.required" v-model="formData.mail_template.text"
                      label="Texto do e-mail" hint="Campo para o texto no corpo do e-mail" persistent-hint>
                    </v-textarea>
                  </v-col>
                  <v-col class="pl-5" cols="4">
                    <v-checkbox v-model="formData.profile_data.automatic_generation"
                      label="Enviar e-mail automaticamente" color="primary"></v-checkbox>
                  </v-col>
                </v-row>
              </v-form>
              <!-- logo_image -->

            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </v-card-text>
      <v-card-actions class="px-0 mx-3" style="border-top: 1px solid rgb(220, 220, 220)">
        <p style="font-size: 13px; margin-top: -25px;" class="pt-5 mb-0 pl-2"><small><b
              style="color: rgb(59, 137, 255);">Obs:</b>
            Os campos com <span style="font-size: 15px; color: rgb(59, 137, 255);">✦</span>
            definem regras para todos os boletos e não são obrigatórios, mas você pode altera-los individualmente na
            área
            de geração de boletos.</small></p>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="toogleRegisterProfileDilog">
          Fechar
        </v-btn>
        <v-btn color="green" text @click="saveProfile"> Salvar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const resetFormProfile = {
  profile_name: "",
  objBank: null,
  mail_template: {
    subject: "",
    title: "",
    text: "",
    logo_image: null,
    footer_message: "",
  },
  profile_data: {
    valorAbatimento: "",
    codigoTipoTitulo: "",
    codigoAceite: "",
    descricaoTipoTitulo: "",
    automatic_generation: true,
    codigoModalidade: null,
    quantidadeDiasProtesto: null,
    quantidadeDiasNegativacao: null,
    indicadorAceiteTituloVencido: null,
    numeroDiasLimiteRecebimento: null,
    indicadorPermissaoRecebimentoParcial: null,
    // numeroTituloBeneficiario: null,
    campoUtilizacaoBeneficiario: null,
    indicadorPix: null,
    desconto: {
      tipo: null,
      dataExpiracao: null,
      porcentagem: null,
      valor: null,
    },
    jurosMora: {
      tipo: null,
      porcentagem: null,
      valor: null,
    },
    multa: {
      tipo: null,
      data: null,
      porcentagem: null,
      valor: null,
    },
  },
};
import * as moment from "moment";
export default {
  watch: {
    registerProfileDilog(v) {
      console.log("passa");
      if (this.tab) {
        this.tab = 4;
      }
      setTimeout(() => {
        this.tab = 0;
      }, 10);
      if (v) {
        if (this.editProfileData) {
          //temporario para edicao
          if (!this.editProfileData.mail_template) {
            this.editProfileData.mail_template = {
              subject: "",
              title: "",
              text: "",
              logo_image: null,
              footer_message: "",
            };
          }
          //temporario para edicao
          this.formData = {
            objBank: {
              pg_bank_favoured_id: this.editProfileData.pg_bank_favoured_id,
              bank_name: this.editProfileData.bank.bank_name,
              number_bank: this.editProfileData.bank.number_bank,
            },
            ...this.editProfileData,
          };
          if (this.formData.mail_template && this.formData.mail_template.logo_image) {
            this.disabEmailFields = true
            this.imageSrc = this.formData.mail_template.logo_image
            this.cover(this.formData.mail_template.logo_image)
          }
        }
      } else {
        this.disabEmailFields = false
        this.$refs.profileForm.resetValidation();
        this.$refs.profileMailForm ? this.$refs.profileMailForm.resetValidation() : '';
        // console.log('reset frm')


        this.formData = {
          profile_name: "",
          objBank: null,
          mail_template: {
            subject: "",
            title: "",
            text: "",
            logo_image: null,
            footer_message: "",
          },
          profile_data: {
            valorAbatimento: "",
            codigoTipoTitulo: "",
            codigoAceite: "",
            descricaoTipoTitulo: "",
            automatic_generation: true,
            codigoModalidade: null,
            quantidadeDiasProtesto: null,
            quantidadeDiasNegativacao: null,
            indicadorAceiteTituloVencido: null,
            numeroDiasLimiteRecebimento: null,
            indicadorPermissaoRecebimentoParcial: null,
            // numeroTituloBeneficiario: null,
            campoUtilizacaoBeneficiario: null,
            indicadorPix: null,
            desconto: {
              tipo: null,
              dataExpiracao: null,
              porcentagem: null,
              valor: null,
            },
            jurosMora: {
              tipo: null,
              porcentagem: null,
              valor: null,
            },
            multa: {
              tipo: null,
              data: null,
              porcentagem: null,
              valor: null,
            },
          },
        }



        // this.formData = { ...resetFormProfile };
        // this.formData.profile_data = { ...resetFormProfile.profile_data }
        // this.formData.profile_data.multa = { ...resetFormProfile.profile_data.multa }

        // this.formData.profile_data.desconto = { ...resetFormProfile.profile_data.desconto }
        // this.formData.profile_data.jurosMora = { ...resetFormProfile.profile_data.jurosMora }

        // this.formData.mail_template = {
        //     subject: "",
        //     title: "",
        //     text: "",
        //     logo_image: null,
        //     footer_message: "",
        //   }
        // if (this.editProfileData) {
        //   this.editProfileData.mail_template = {
        //     subject: "",
        //     title: "",
        //     text: "",
        //     logo_image: null,
        //     footer_message: "",
        //   }
        // }
        this.clearImage();
      }
    },
  },
  methods: {
    validTabs() {
      let tabsFrm = document.getElementsByClassName('tabValidatorProfile')
      let tabs = document.getElementsByClassName('v-tab')
      for (let i = 0; i < tabsFrm.length; i++) {
        if (tabsFrm[i].getElementsByClassName('error--text').length) {
          if (i > 0) {
            this.tab = 2
          } else {
            this.tab = 0
            return
          }
          // tabs[i].classList.add("tabHasErrorProfile");
        } else {
          // tabs[i].classList.remove("tabHasErrorProfile");
        }

      }
    },
    clearImage() {
      this.imageSrc = "";
      this.formData.mail_template.logo_image = null;
    },
    cover(url) {
      if (url !== "") {
        // You can check any matching expression.
        try {
          url = require(this.imageSrc);
        } catch (e) {
          // url = require("/assets/img/default.jpg"); // I used a default image.
        }
      } else {
        //url = require("../assets/logo-durand-branco.png");
      }
      return url;
    },
    uploadImage: function () {
      let _this = this;
      var file = document.querySelector("input[type=file]").files[0];
      var reader = new FileReader();
      reader.onload = function (e) {
        _this.imageSrc = e.target.result;
      };
      reader.onerror = function (error) {
        alert(error);
      };
      reader.readAsDataURL(file);
    },
    saveProfile() {
      if (!this.$refs.profileForm.validate()) {
        setTimeout(() => {
          this.validTabs()
        }, 100);
        return;
      }
      if (this.disabEmailFields && this.$refs.profileMailForm && !this.$refs.profileMailForm.validate()) {
        this.tab = 4
        // setTimeout(() => {
        //   this.validTabs()
        // }, 10);
        return
      }
      // this.validTabs()
      this.formData.mail_template.logo_image = this.imageSrc ? this.imageSrc : null;
      let query = {
        number_bank: this.formData.objBank.number_bank,
        pg_bank_favoured_id: this.formData.objBank.pg_bank_favoured_id,
        ...this.formData,
      };
      // console.log("vvv", query);
      // return;
      var obj = {
        url: this.formData.id
          ? "/api/v1/title/user/profile/update"
          : "/api/v1/title/user/profile/create",
        query: query,
        method: "post",
      };
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        if (!response) {
          return;
        }
        let opts = {
          message: `Perfil ${this.formData.id ? "atualizado" : "criado"
            } com sucesso`,
          type: "success",
        };
        this.$store.commit("showAlert", opts);
        this.toogleRegisterProfileDilog();
        this.getProfiles().then((_) => {
          //this.verifySteps();
        });
      });
    },
  },
  data() {
    return {
      disabEmailFields: false,
      imageSrc: "",
      dataMenu: false,
      dataExpiracaoMenu: false,
      tab: 0,
      formData: { ...resetFormProfile },
      modalidades: [
        // { id: null, name: "Selecione" },
        { id: 1, name: "Simples" },
        { id: 4, name: "Vinculada" },
      ],
      aceiteVencidos: [
        // { id: null, name: "Selecione" },
        { id: "S", name: "Sim" },
        { id: "N", name: "Não" },
      ],
      aceiteValorParcial: [
        // { id: null, name: "Selecione" },
        { id: "S", name: "Sim" },
        { id: "N", name: "Não" },
      ],
      utilizaPix: [
        // { id: null, name: "Selecione" },
        { id: "S", name: "Sim" },
        { id: "N", name: "Não" },
      ],
      tipoDesconto: [
        { id: 0, name: "Sem desconto" },
        { id: 1, name: "Valor fixo até a data informada" },
        { id: 2, name: "Percentual até a data informada" },
        { id: 3, name: "Desconto por dia antecipado" },
      ],
      tipoMulta: [
        // { id: null, name: "Selecione" },
        { id: 0, name: "Dispensar" },
        { id: 1, name: "Valor por dia de atraso" },
        { id: 2, name: "Taxa mensal" },
      ],
      tipoJuros: [
        // { id: null, name: "Selecione" },
        { id: 0, name: "Dispensar" },
        { id: 1, name: "Valor por dia de atraso" },
        { id: 2, name: "Taxa mensal" },
      ],
    };
  },
  mounted() {
    //this.getConfiguredBanks();
  },
  props: {
    verifySteps: Function,
    getProfiles: Function,
    // banksProfileRegisterCbo: Array,
    registerProfileDilog: Boolean,
    toogleRegisterProfileDilog: Function,
    editProfileData: Object,
  },
  computed: {
    dataFormated: {
      get: function () {
        let date = this.formData.profile_data.multa.data
          ? moment(this.formData.profile_data.multa.data).format("DD/MM/YYYY")
          : "";
        return date == "Invalid date" ? null : date;
      },
      // setter
      set: function (newValue) {
        let date = moment(this.formData.profile_data.multa.data).format(
          "DD/MM/YYYY"
        );
        // console.log("aaaa", newValue);
        if (date == "Invalid date") {
          return null;
        }
        if (!newValue) {
          this.formData.profile_data.multa.data = newValue;
        }
        return newValue;
      },
    },
    dataExpiracaoFormated: {
      get: function () {
        let date = this.formData.profile_data.desconto.dataExpiracao
          ? moment(this.formData.profile_data.desconto.dataExpiracao).format(
            "DD/MM/YYYY"
          )
          : "";
        return date == "Invalid date" ? null : date;
      },
      // setter
      set: function (newValue) {
        let date = moment(
          this.formData.profile_data.desconto.dataExpiracao
        ).format("DD/MM/YYYY");
        // console.log("aaaa", newValue);
        if (date == "Invalid date") {
          return null;
        }
        if (!newValue) {
          this.formData.profile_data.desconto.dataExpiracao = newValue;
        }
        return newValue;
      },
    },
  },
};
</script>

<style>
.wrapImg {
  position: relative;
  background: #f1f1f1;
  border-radius: 10px;
}

.closeImg {
  position: absolute;
  right: -7px;
  top: -10px;
  box-shadow: 2px 1px 3px grey;
}

.v-tab.tabHasErrorProfile {
  color: red !important;
}

.v-tab.tabHasErrorProfile::after {
  content: "*";
}
</style>