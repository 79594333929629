var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"px-8"},[_c('Filters',{attrs:{"profilesCbos":_vm.profilesCbos,"banks":_vm.banksProfileRegisterCbo,"moreFilters":_vm.moreFilters,"getBillets":_vm.getBillets}})],1),_c('v-card-text',{staticClass:"px-15 mt-5"},[_c('v-data-table',{staticClass:"newPagination elevation-0 scrollTable",attrs:{"headers":_vm.headers,"items":_vm.billets,"no-data-text":"Utilize os filtros acima para listar boletos"},scopedSlots:_vm._u([{key:"item.dataEmissao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.dataEmissao))+" ")]}},{key:"item.dataVencimento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.dataVencimento))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({class:_vm.setStatusBillet(item.status)[3],attrs:{"color":_vm.setStatusBillet(item.status)[0],"center":""},on:{"click":function($event){_vm.setStatusBillet(item.status)[2].indexOf('Falha') < 0
                                ? null
                                : _vm.confirmRetryRegisterBillet(item, $event)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.setStatusBillet(item.status)[1])+" ")])]}}],null,true)},[_c('span',{staticClass:"miniTtip",domProps:{"innerHTML":_vm._s(_vm.setStatusBillet(item.status)[2])}})])]}},{key:"item.data_title.pagador.nome",fn:function(ref){
                                var item = ref.item;
return [_vm._v(" "+_vm._s(item.data_title.pagador.nome || "Não identificado")+" ")]}},{key:"item.value",fn:function(ref){
                                var item = ref.item;
return [_vm._v(" R$ "+_vm._s(_vm._f("formatPrice")(item.value))+" ")]}},{key:"footer.page-text",fn:function(){return [_c('v-row',{staticClass:"d-flex justify-end"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"5"}},[_c('v-container',{staticClass:"d-flex flex-row justify-end max-width pr-0"},[_c('v-pagination',{attrs:{"length":_vm.totalPages,"total-visible":8},model:{value:(_vm.actualPage),callback:function ($$v) {_vm.actualPage=$$v},expression:"actualPage"}})],1)],1),_c('v-col',{staticClass:"pt-9 px-0 pb-0",staticStyle:{"max-width":"130px"},attrs:{"cols":"2"}},[_c('span',{staticClass:"caption"},[_vm._v("Itens por página")])]),_c('v-col',{staticClass:"pb-0",staticStyle:{"max-width":"90px"},attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":[2, 5, 10, 50, 100]},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1)],1)]},proxy:true}]),model:{value:(_vm.selectedsBillets),callback:function ($$v) {_vm.selectedsBillets=$$v},expression:"selectedsBillets"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }