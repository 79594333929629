<template>
    <v-dialog persistent v-model="creditCardLogDilog" scrollable max-width="1600px">
        <v-card>
            <v-card-title style="border-bottom: 1px solid #ccc;" class="px-2 pb-5"><v-app-bar height="50" flat
                    color="rgba(0, 0, 0, 0)">
                    <v-toolbar-title class="text-h5 pl-0 pt-0">
                        Logs de transações de cartões de crédito
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn @click="toogleCreditCardLogDilog" class="mt-7" color="red" fab icon dark x-small absolute top
                        right>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-app-bar>
            </v-card-title>
            <v-card-text>

                <LogFilters :creditCardLogDilog="creditCardLogDilog" :getLogs="getLogs" :perPage="perPage" />
                <LogErrorDeatil :logErrorDeatilDilog="logErrorDeatilDilog"
                    :toogleLogErrorDeatilDilog="toogleLogErrorDeatilDilog" :jsonError="jsonError"
                    :stringError="stringError" propsSubTitle="Informações registradas" :propsTitle="propsTitle" />

                <v-data-table no-data-text="Utilize os filtros acima e faça sua pesquisa"
                    :height="logsList.length >= 2 ? '' : '250'" :page="1" :itemsPerPage="perPage" hide-default-footer
                    light :headers="headers" :items="logsList" class="emptyHeight elevation-0 opacityTable mx-15">
                    <template v-slot:item.sacado="{ item }">
                        <div>
                            {{ item.sacado }}
                        </div>
                        <div class="caption font-weight-medium">
                            {{ item.email_sacado }}
                        </div>
                    </template>

                    <template v-slot:item.description.message="{ item }">
                        <div class="mt-2">
                            <small class="mr-1">Msg:</small><span class="font-weight-medium">{{ item.description.message
                                }}</span>
                        </div>
                        <div class="mt-1 mb-2">
                            <small class="mr-1">Cod:</small><span class="font-weight-medium">
                                {{ item.transaction_code ? item.transaction_code : '-'}}</span>
                        </div>
                    </template>

                    <template v-slot:item.event="{ item }">
                        <div>{{ item.event.split(" ")[0] | formatDate }}</div>
                        <div class="caption">
                            <v-icon class="mb-1 font-weight-black" x-small>schedule</v-icon>
                            {{ item.event.split(" ")[1] }}
                        </div>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" class="btMicro px-1 mr-3" @click="getMoreDetails(item)"
                                    x-small :color="item.type == 'ERROR' ? 'error' : 'primary'" dark><v-icon center
                                        x-small>
                                        error_outline
                                    </v-icon></v-btn>
                            </template>
                            <span class="miniTtip">{{
                                item.type == "ERROR"
                                    ? "Detalhes do erro"
                                    : "Ver mais detalhes"
                            }}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
                <v-row class="mx-15 d-flex flex-row-reverse bordTop">
                    <v-col style="max-width: 75px" class="d-flex pr-0" cols="12" sm="1">
                        <v-select v-model="perPage" :items="[5, 10, 50, 100]"></v-select>
                    </v-col>
                    <v-col style="max-width: 110px" class="pr-1 mb-7 align-end d-flex justify-end col-sm-2 col-12"
                        cols="12" sm="2"><span class="caption">Itens por página</span></v-col>
                    <v-col class="" cols="5">
                        <v-container class="d-flex flex-row justify-end max-width pr-0">
                            <v-pagination v-model="actualPage" :length="totalPages" :total-visible="8"></v-pagination>
                        </v-container>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="px-0 mx-3" style="border-top: 1px solid rgb(220, 220, 220)">
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="toogleCreditCardLogDilog">
                    Fechar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import LogErrorDeatil from "./LogErrorDeatil.vue";
import LogFilters from "./LogFilters.vue";
export default {
    watch: {
        // creditCardLogDilog(v) {
        //     if (!v) {
        //         this.logsList = [];
        //         this.totalPages = 5;
        //         // this.actualPage = response.data.current_page;
        //     }
        // },
        actualPage(v, oldV) {
            //console.log("qsqsqs", oldV);
            if (!oldV || !v) {
                return;
            }
            this.getLogs(this.lastParans, v);
        },
    },
    methods: {
        toogleLogErrorDeatilDilog() {
            this.logErrorDeatilDilog = !this.logErrorDeatilDilog;
        },
        getMoreDetails(data) {
            let respError = "";
            data.type == "ERROR"
                ? (this.propsTitle = "Detalhes do Erro")
                : (this.propsTitle = "Detalhes do Log");

            try {
                // console.log('json monta')
                respError = JSON.parse(
                    data.return.split("Retorno da persistencia: ")[1].split('- App: CIELO')[0]
                    // data.return.split("Retorno da persistencia: ")[1]
                );
                this.jsonError = JSON.stringify(respError, null, 4);
                this.stringError = "";
            } catch (error) {
                this.jsonError = "";
                this.stringError = `Ação: ${data.description.action}
Mensagem: ${data.description.message}
Retorno: ${data.return.split("Retorno da persistencia: ")[1]}`;
            }
            this.toogleLogErrorDeatilDilog();
        },
        getLogs(dataQuery, page) {
            let complementPag = page ? `/?page=${page}` : "";
            // this.setedPage = page ? true : false;
            this.actualPage = page;
            this.lastParans = { ...dataQuery };
            var obj = {
                url: "/api/auditlogs/logs" + complementPag,
                query: dataQuery,
                method: "post",
            };
            //console.log(obj);
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                //console.log("breca!");
                this.logsList = [...response.data.data];
                this.totalPages = response.data.last_page;
                //this.setedPage = true;
                this.actualPage = response.data.current_page;
            });
        },
    },
    data() {
        return {
            logErrorDeatilDilog: false,
            jsonError: "",
            stringError: "",
            propsTitle: "",
            logsList: [],
            setedPage: false,
            totalPages: 0,
            perPage: 5,
            actualPage: 0,
            headers: [
                // { text: "Sacado", value: "sacado", sortable: true },
                { text: "IP", value: "ip_address", sortable: true },
                { text: "Mensagem/Transaction code", value: "description.message", sortable: false },
                {
                    text: "Ação",
                    value: "description.action",
                    sortable: false,
                },
                { text: "Data do evento", value: "event", sortable: false },
                { text: "Opções", value: "actions", sortable: false },
            ],
        }
    },
    props: {
        creditCardLogDilog: Boolean,
        toogleCreditCardLogDilog: Function
    },
    components: {
        LogFilters,
        LogErrorDeatil
    },
}
</script>

<style>
.emptyHeight .v-data-table__empty-wrapper {
    height: 200px;
}
</style>