<template>
    <div class="mt-5">
        <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
                <v-btn small @click="toogleRegisterOperatorDilog" v-bind="attrs" v-on="on" class="mr-10 mt-XXL"
                    color="primary" fab dark absolute top right>
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </template>
            <span>Configurar nova operadora</span>
        </v-tooltip>

        <v-btn style="margin-top: -20px;" small @click="toogleCreditCardTransectionsDialog" class="ml-10" color="primary"
            dark absolute top left>
            Minhas transações
        </v-btn>

        <v-card-text class="px-15 pt-10">
            <v-data-table :headers="headers" :items="operators" :items-per-page="5" class="elevation-0"
                no-data-text="Nenhuma operadora configurada">
                <template v-slot:item.created_at="{ item }">
                    {{ buildDate(item.created_at) }}
                </template>
                <template v-slot:item.credentials="{ item }">
                    <div class="py-2"><small><b>merchant id:</b></small> {{ item.credentials.merchant_id }}</div>
                    <div class="pb-2"><small><b>merchant key:</b></small> {{ item.credentials.merchant_key }}</div>
                </template>

                <template v-slot:item.actions="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" class="btMicro px-1 mr-1" @click="editConfigOperator(item)"
                                x-small color="primary" dark><v-icon center x-small> edit </v-icon></v-btn>
                        </template>
                        <span class="miniTtip">Editar configuração</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" class="btMicro px-1" @click="confirmDelConfigOperator(item)"
                                x-small color="red" dark><v-icon center x-small> mdi-delete-forever
                                </v-icon></v-btn>
                        </template>
                        <span class="miniTtip">Excluir configuração</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-card-text>

        <RegisterOperator :adm="false" :configuredOperators="configuredOperators" :editConfigData="editConfigData"
            :registerOperatorDilog="registerOperatorDilog" :toogleRegisterOperatorDilog="toogleRegisterOperatorDilog"
            :getOperators="getOperators" />
        <TransectionCreditCard :creditCardTransectionsDialog="creditCardTransectionsDialog"
            :toogleCreditCardTransectionsDialog="toogleCreditCardTransectionsDialog" />
    </div>
</template>

<script>
import RegisterOperator from "../clients/billets/CreditCards/RegisterOperator.vue";
import TransectionCreditCard from "../clients/billets/CreditCards/TransectionCreditCard.vue";

export default {
    watch: {
        registerOperatorDilog(v) {
            if (!v) {
                this.editConfigData = null;
            }
        }
    },
    methods: {
        toogleCreditCardTransectionsDialog() {
            this.creditCardTransectionsDialog = !this.creditCardTransectionsDialog
        },
        getOperators() {
            var obj = {
                url: "/api/v1/paymentCredentials/getAll",
                query: null,
                method: "post",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                this.operators = [...response.data];
            });
        },
        getConfiguredOperators() {
            // function que carrega o select do form
            var obj = {
                url: "/api/v1/paymentGateways/getAll",
                query: null,
                method: "post",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                this.configuredOperators = [...response.data];
            });
        },
        buildDate(date) {
            let day = new Date(date).getDate()
            let month = date.split('-')[1]
            let yaer = date.split('-')[0]
            return day + "/" + month + "/" + yaer
        },
        editConfigOperator(data) {
            let obj = {

            };
            this.editConfigData = { ...data };
            // console.log('edit', this.editConfigData)
            this.toogleRegisterOperatorDilog();
        },
        confirmDelConfigOperator(data) {
            let obj = {
                message: `Você deseja excluir a configuração da operadora <b>${data.name}</b>?`,
                title: "Excluir",
                callback: (_) => {
                    this.delConfigOperator(data);
                },
                type: "warning",
                btConfirm: { name: "Sim", show: true },
                btCancel: { name: "Não", show: true },
            };
            this.$store.commit("confirmMessage/showConfirmMessage", obj);
        },
        delConfigOperator(data) {
            // "partner_id":68,
            //   "contract_id":129,
            //   "service_id" : 3,
            //   "id":5
            let obj = {
                url: "/api/v1/paymentCredentials/delete",
                query: {
                    id: data.id,
                    name: data.name
                    // service_id: 3,
                    // partner_id: localStorage.getItem("partner_id"),
                },
                method: "post",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                // let index = this.payment.findIndex((payment) => payment.id === data.id);
                // this.payment.splice(index, 1);
                let opts = {
                    message: `Configuração removida com sucesso.`,
                    type: "success",
                };
                this.$store.commit("showAlert", opts);
                this.getOperators();
            });
        },
        toogleRegisterOperatorDilog() {
            this.registerOperatorDilog = !this.registerOperatorDilog;
            if (!this.registerOperatorDilog) {
                this.editConfigData = null;
            }
        },
    },
    data() {
        return {
            creditCardTransectionsDialog: false,
            configuredOperators: [],
            operators: [],
            editConfigData: null,
            registerOperatorDilog: false,
            headers: [
                {
                    text: "Nome",
                    value: "name",
                    align: "left",
                    sortable: false,
                },
                {
                    text: "Data de início",
                    value: "created_at",
                    align: "center",
                    sortable: false,
                },
                {
                    text: "Credenciais",
                    value: "credentials",
                    align: "left",
                    sortable: false,
                },
                {
                    text: "Url de callback",
                    value: "callback_url",
                    align: "left",
                    sortable: false,
                },
                {
                    text: "Ações",
                    value: "actions",
                    align: "center",
                    sortable: false,
                },
            ],
        };
    },
    mounted() {
        this.getConfiguredOperators();
        this.getOperators();
    },
    components: {
        RegisterOperator, TransectionCreditCard
    },
    computed: {
        responsiveSize() {
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return 220;
                case "sm":
                    return 400;
                case "md":
                    return 500;
                case "lg":
                    return 600;
                case "xl":
                    return 800;
            }
        },
    },
}
</script>

<style></style>