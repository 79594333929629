<template>
  <v-dialog persistent v-model="registerbankDilog" scrollable max-width="850px">
    <v-card>
      <v-card-title style="border-bottom: 1px solid #ccc;" class="px-2"><v-app-bar height="50" flat
          color="rgba(0, 0, 0, 0)">
          <v-toolbar-title class="text-h5 pl-0 pt-0">
            {{ false ? "Editar banco" : "Novo banco" }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-fab-transition> -->
          <v-btn @click="toogleRegisterbankDilog" class="mt-6" color="red" fab icon dark x-small absolute top right>
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <!-- </v-fab-transition> -->
        </v-app-bar></v-card-title>

      <v-card-text class="pt-0">
        <v-form ref="bankForm" style="width: 100%">
          <v-tabs color="primaryNew" v-model="tabs">
            <v-tabs-slider class="pa-5"></v-tabs-slider>
            <v-tab class=""> Dados do banco </v-tab>
            <v-tab class=""> Tarifas do banco </v-tab>
            <v-tab class=""> Numeração de boletos </v-tab>
            <v-tab class=""> Credenciais </v-tab>
          </v-tabs>
          <v-tabs-items light v-model="tabs" style="min-height: 350px; border-top: 1px solid #ccc">
            <v-tab-item>
              <v-row class="px-3 mt-4">
                <v-col class="pl-5 pt-0" cols="6">
                  <!-- hint="Digite o nome do usuário"
                persistent-hint -->
                  <v-combobox class="requiredField" :rules="$store.state.formRules.required" clearable
                    v-model="formData.bank" :items="banks" label="Selecione um banco" item-text="bank_name"
                    item-value="number_bank" @change="teste"></v-combobox>
                </v-col>
                <v-col class="pl-5 pt-0" cols="6">
                  <v-text-field clearable class="requiredField" :rules="$store.state.formRules.required"
                    v-model="formData.agencia" label="Agência"></v-text-field>
                  <!-- hint="Número do convênio"
                persistent-hint -->
                </v-col>
                <v-col class="pl-5 pt-0" cols="6">
                  <v-text-field clearable class="requiredField" :rules="$store.state.formRules.required"
                    v-model="formData.contaCorrente" label="Conta corrente"></v-text-field>
                  <!-- hint="Número do convênio"
                persistent-hint -->
                </v-col>
                <v-col class="pl-5 pt-0" cols="6">
                  <v-text-field clearable class="requiredField" :rules="$store.state.formRules.required"
                    v-model="formData.numeroConvenio" label="Número do convenio"></v-text-field>
                  <!-- hint="Número do convênio"
                persistent-hint -->
                </v-col>
                <v-col class="pl-5 pt-0" cols="6">
                  <v-text-field clearable class="" v-model="formData.numeroCarteira"
                    label="Número da carteira"></v-text-field>
                  <!-- hint="Número do convênio"
                persistent-hint -->
                </v-col>
                <v-col class="pl-5 pt-0" cols="6">
                  <v-text-field clearable class="" v-model="formData.numeroVariacaoCarteira"
                    label="Número de variação da carteira"></v-text-field>
                  <!-- hint="Número do convênio"
                persistent-hint -->
                </v-col>
                <v-col class="pl-5 pt-0" cols="6">
                  <v-text-field clearable class="" v-model="formData.orgaoNegativador"
                    label="Orgao negativador"></v-text-field>
                  <!-- hint="Número do convênio"
                persistent-hint -->
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row class="px-3 mt-4">
                <v-col class="pl-5 py-0" cols="12">
                  <div class="infoText pt-2">
                    <h6 class="subtitle-1 grey--text text--darken-1 font-weight-medium">
                      <v-icon color="primary" class="mr-2 mb-1">info</v-icon>Leia atentamente
                    </h6>
                    <p class="caption grey--text text--darken-1 mb-5 mt-1 ml-1">
                      Os valores abaixo são definidos no seu contrato com o banco. Informe apenas os valores que seu
                      contrato possui.
                    </p>
                  </div>
                </v-col>
                <v-col class="pl-5" cols="6">
                  <v-text-field-money class="requiredField" v-model="formData.tarifaLiquidado"
                    v-bind:label="'Valor por boletor liquidado'" v-bind:properties="{
                      rules: $store.state.formRules.required,
                      prefix:
                        formData.tarifaLiquidado &&
                          formData.tarifaLiquidado.length
                          ? 'R$'
                          : '',
                      readonly: false,
                      outlined: false,
                      clearable: true,
                      placeholder: ' ',
                      hint: 'Valor cobrado pelo seu banco após o boleto ser pago',
                      'persistent-hint': true
                    }" v-bind:options="{
                      locale: 'pt-BR',
                      length: 11,
                      precision: 2,
                      empty: null,
                    }" />
                </v-col>
                <v-col class="pl-5" cols="6">
                  <v-text-field-money class="" v-model="formData.tarifaRegistro"
                    v-bind:label="'Valor por boletor registrado'" v-bind:properties="{
                      prefix:
                        formData.tarifaRegistro &&
                          formData.tarifaRegistro.length
                          ? 'R$'
                          : '',
                      readonly: false,
                      outlined: false,
                      clearable: true,
                      placeholder: ' ',
                      hint: 'Valor cobrado pelo seu banco após o boleto ser registrado',
                      'persistent-hint': true
                    }" v-bind:options="{
                      locale: 'pt-BR',
                      length: 11,
                      precision: 2,
                      empty: null,
                    }" />
                </v-col>
                <v-col class="pl-5" cols="6">
                  <v-text-field-money class="" v-model="formData.tarifaBaixa"
                    v-bind:label="'Valor por boletor baixado/cancelado'" v-bind:properties="{
                      prefix:
                        formData.tarifaBaixa &&
                          formData.tarifaBaixa.length
                          ? 'R$'
                          : '',
                      readonly: false,
                      outlined: false,
                      clearable: true,
                      placeholder: ' ',
                      hint: 'Valor cobrado pelo seu banco após o boleto ser baixado, ou cancelado',
                      'persistent-hint': true
                    }" v-bind:options="{
                      locale: 'pt-BR',
                      length: 11,
                      precision: 2,
                      empty: null,
                    }" />
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row class="px-3 mt-4">
                <v-col class="pl-5 py-0" cols="12">
                  <div class="infoText pt-2">
                    <h6 class="subtitle-1 grey--text text--darken-1 font-weight-medium">
                      <v-icon color="primary" class="mr-2 mb-1">info</v-icon>Leia atentamente
                    </h6>
                    <p class="caption grey--text text--darken-1 mb-5 mt-1 ml-1">
                      Você pode escolher um algorítimo de numerção para os seus boletos e
                      futuramente mudar, mas é importante se atentar a possíveis problemas de inconsistencia.
                    </p>
                  </div>
                </v-col>
                <v-col class="pl-5 pt-0" cols="12">
                  <v-select class="requiredField" :rules="$store.state.formRules.required" label="Tipo de numeração"
                    v-model="formData.numeration.algoritmoNumeracao" :items="algoritimo" item-text="label"
                    item-value="val"></v-select>
                  <!-- <v-text-field clearable class="" v-model="formData.algoritmoNumeracao"
                    label="Algorítimo de numeração"></v-text-field> -->
                  <!-- hint="Número do convênio"
                persistent-hint -->
                </v-col>
                <v-col class="pl-5 pt-0" cols="5">
                  <v-text-field type="number" min="0" clearable class="" v-model="formData.numeration.numeracaoInicial"
                    label="Numeração inicial"></v-text-field>
                  <!-- hint="Número do convênio"
                persistent-hint -->
                </v-col>
                <v-col class="pl-5 pt-0" cols="7">
                  <v-text-field disabled type="number" min="0" class="" v-model="formData.numeration.last_title_number"
                    label="Último número gerado" hint="ùltimo número gerado na plataforma Community Ware"
                    persistent-hint></v-text-field>
                </v-col>
                <v-col class="pl-5 pt-0" cols="12">
                  <v-text-field :disabled="formData.numeration.last_title_number ? false : true" type="number" min="0"
                    clearable class="" persistent-hint v-model="formData.numeration.nextTitleNumber"
                    label="Alterar último número gerado"
                    hint="Caso você tenha gerado boletos em outra plataforma e a numeração atual não esteja correta é possível fazer a alteração do último número gerado."></v-text-field>

                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row class="px-3 mt-4">
                <v-col class="pl-5 py-0" cols="12">
                  <div class="infoText pt-2">
                    <h6 class="subtitle-2 grey--text text--darken-1">
                      Onde encontrar minhas credenciais<v-icon color="primary" class="ml-2 mb-1">help</v-icon>
                    </h6>
                    <p style="max-width: 500px;" class="caption grey--text text--darken-1">
                      As credenciais são informadas pelo banco quando é feito o seu cadastro para utilização de boletos.
                    </p>
                  </div>
                </v-col>
                <v-col class="pl-5 pt-0" cols="6">
                  <v-text-field clearable class="requiredField" :rules="$store.state.formRules.required"
                    v-model="formData.credentials.auth_code" label="Auth code"></v-text-field>
                </v-col>
                <v-col class="pl-5 pt-0" cols="6">
                  <v-text-field clearable class="requiredField" :rules="$store.state.formRules.required"
                    v-model="formData.credentials.app_key" label="App key"></v-text-field>
                </v-col>
                <v-col class="pl-5 pt-0" cols="6">
                  <v-text-field clearable class="requiredField" :rules="$store.state.formRules.required"
                    v-model="formData.credentials.client_id" label="Client id"></v-text-field>
                </v-col>
                <v-col class="pl-5 pt-0" cols="6">
                  <v-text-field clearable class="requiredField" :rules="$store.state.formRules.required"
                    v-model="formData.credentials.client_secret" label="Client secret"></v-text-field>
                </v-col>
                <v-col class="pl-5 pt-0" cols="12">
                  <v-text-field clearable class="" v-model="formData.callback_url"
                    label="Url de callback"></v-text-field>
                </v-col>
              </v-row>
            </v-tab-item>

          </v-tabs-items></v-form>
      </v-card-text>

      <v-card-actions class="px-0 mx-3" style="border-top: 1px solid rgb(220, 220, 220)">
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="toogleRegisterbankDilog">
          Fechar
        </v-btn>
        <v-btn color="green" text @click="saveConfigBank"> Salvar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const resetFormBank = {
  bank: "",
  agencia: "",
  contaCorrente: "",
  numeroConvenio: "",
  numeroCarteira: "",
  orgaoNegativador: "",
  numeroVariacaoCarteira: "",
  callback_url: "",
  tarifaLiquidado: "",
  tarifaRegistro: "",
  tarifaBaixa: "",
  numeration: {
    nextTitleNumber: "",
    last_title_number: "",
    numeracaoInicial: "0",
    algoritmoNumeracao: "",
  },
  credentials: {
    auth_code: "",
    app_key: "",
    client_id: "",
    client_secret: ""
  }
};
export default {
  mounted() {
    this.getCboBanks();
  },
  watch: {
    registerbankDilog(v) {
      // console.log("passa");
      if (v) {
        if (this.editConfigData) {
          // console.log("watch", this.editConfigData);
          // this.editConfigData.algoritmoNumeracao = this.editConfigData.title_config.algoritmoNumeracao
          this.formData = { ...this.editConfigData };
        }
      } else {
        this.tabs = 0
        this.$refs.bankForm.resetValidation();
        this.formData = { ...resetFormBank };
      }
    },
  },
  methods: {
    teste() {
      console.log('ddddd')
    },
    saveConfigBank() {
      console.log('bbbb');
      // return;
      if (!this.$refs.bankForm.validate()) {
        return;
      }
      this.formData.number_bank = this.formData.bank.number_bank;
      //this.formData.service_id = 3;
      this.formData.partner_id = localStorage.getItem("partner_id");
      let query = {
        ...this.formData,
      };
      delete query.bank;
      var obj = {
        url: `${this.formData.id
          ? "/api/v1/title/user/bank/updateTitleConfig"
          : "/api/v1/title/user/bank/createUserBank"
          }`,
        query: query,
        method: "post",
      };
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        if (!response) {
          return;
        }
        if (this.adm) {
          console.log('not - async')
          this.getConfiguredBanks()
          this.toogleRegisterbankDilog()
        } else {
          console.log('async')
          this.getConfiguredBanks().then((_) => {
            this.toogleRegisterbankDilog();
            // if (this.verifySteps) {
            //   this.verifySteps()
            // }
          });
        }
        let opts = {
          message: `Configuração ${this.formData.id ? "atualizada" : "criada"
            } com sucesso`,
          type: "success",
        };
        this.$store.commit("showAlert", opts);
      });
    },
    getCboBanks() {
      var obj = {
        noLoader: true,
        url: "/api/title/bank/get-all",
        query: null,
        method: "get",
      };

      this.$store.dispatch("request/doRequest", obj).then((response) => {
        if (!response) {
          return;
        }
        //console.log("bancos", response.data);
        this.banks = [...response.data];
      });
    },
  },
  data() {
    return {
      algoritimo: [
        { val: "A", label: "Tipo (A) - Poderá emitir 9.999 boletos por dia" },
        { val: "B", label: "Tipo (B) - Poderá emitir 999.999 boletos por mês" },
        { val: "C", label: "Tipo (C) - Poderá emitir 9.999.999 boletos por ano" },
        { val: "D", label: "Tipo (D) - Poderá emitir 9.999.999.999 boletos" }
      ],
      tabs: null,
      formData: { ...resetFormBank },
      banks: [
        //{ id: 1, bank_name: "Banco do Barsil" },
        //{ id: 2, bank_name: "Banco de teste" },
      ],
    };
  },
  props: {
    adm: Boolean,
    verifySteps: Function,
    registerbankDilog: Boolean,
    toogleRegisterbankDilog: Function,
    editConfigData: Object,
    getConfiguredBanks: Function,
  },
};
</script>

<style></style>