<template>
    <div class="pl-16 pr-2 mb-3">
        <v-card :min-height="windowH" class="mx-auto mt-3 bgTransparente" max-width="calc(100% - 10px)" elevation="0">
            <v-card-title class="ml-10 pt-4 pb-5 pl-0 black--text">
                <v-tabs v-model="tab">
                    <v-tab>Cartão de crédito</v-tab>
                    <v-tab>Configuração de banco</v-tab>
                    <v-tab>Perfis de cobrança</v-tab>
                    <v-tab>Meus boletos</v-tab>
                </v-tabs>
            </v-card-title>
            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <OperatorListCreditCard :admArea="true" />
                </v-tab-item>
                <ConfigBankAdm />
                <v-tab-item>
                    <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn small @click="toogleRegisterProfileDilog" v-bind="attrs" v-on="on" class="mt-0 mr-10"
                                color="primary" fab dark :fixed="responsiveSize < 500" :absolute="responsiveSize >= 500"
                                :bottom="responsiveSize < 500" :top="responsiveSize >= 500" right>
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Novo perfil</span>
                    </v-tooltip>
                    <v-card-text class="px-15 mt-5">
                        <v-data-table :headers="headers" :items="profiles" :items-per-page="5" class="elevation-0"
                            no-data-text="Nenhum perfil cadastrado">
                            <template v-slot:item.profile_data.indicadorPix="{ item }">
                                {{ item.profile_data.indicadorPix == "S" ? "Sim" : "Não" }}
                            </template>
                            <template v-slot:item.profile_data.desconto.tipo="{ item }">
                                {{ setDiscountTxt(item.profile_data.desconto.tipo) }}
                            </template>

                            <template v-slot:item.actions="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on" class="btMicro px-1 mr-1"
                                            @click="editProfile(item)" x-small color="primary" dark><v-icon center
                                                x-small> edit </v-icon></v-btn>
                                    </template>
                                    <span class="miniTtip">Editar perfil</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on" class="btMicro px-1"
                                            @click="confirmDelProfile(item)" x-small color="red" dark><v-icon center
                                                x-small> mdi-delete-forever </v-icon></v-btn>
                                    </template>
                                    <span class="miniTtip">Excluir perfil</span>
                                </v-tooltip>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-tab-item>
                <v-tab-item>
                    <BilletList />
                </v-tab-item>
            </v-tabs-items>
        </v-card>
        <ProfileRegister :verifySteps="verifySteps" :getProfiles="getProfiles"
            :banksProfileRegisterCbo="banksProfileRegisterCbo" :editProfileData="editProfileData"
            :toogleRegisterProfileDilog="toogleRegisterProfileDilog" :registerProfileDilog="registerProfileDilog" />
    </div>
</template>

<script>
import BilletList from "./BilletList.vue"
import ProfileRegister from "../clients/billets/profiles/ProfileRegister.vue";
import OperatorListCreditCard from "./OperatorsList.vue";
import ConfigBankAdm from "./ConfigBankAdm.vue"
export default {
    watch: {
        profilesListDialog(v) {
            if (v && this.stepsOn == 2) {
                this.toogleProfilesListDialog();
                this.toogleRegisterProfileDilog();
            }
        },
    },
    methods: {
        getConfiguredBanks(loader) {
            var obj = {
                // noLoader: loader,
                url: "/api/v1/title/user/bank/getUserBank",
                query: null,
                method: "get",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                this.banksProfileRegisterCbo = [
                    ...response.data.map((obj) => {
                        return {
                            number_bank: obj.bank.number_bank,
                            pg_bank_favoured_id: obj.id,
                            bank_name: obj.bank.bank_name,
                        };
                    }),
                ];
                //this.banks = [...response.data];
            });
        },
        getProfiles() {
            var obj = {
                url: "/api/v1/title/user/profile/get-all",
                query: null,
                method: "get",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                this.profilesCbos = [
                    ...response.data.map((prf) => {
                        return { id: prf.id, name: prf.profile_name };
                    }),
                ];
                this.profiles = [...response.data];
            });
        },
        getHeightPage() {
            this.windowH = window.innerHeight - 125;
        },
        confirmDelProfile(data) {
            let obj = {
                message: `Você deseja excluir o perfil <b>${data.profile_name}</b>?`,
                title: "Excluir",
                callback: (_) => {
                    this.delProfile(data);
                },
                type: "warning",
                btConfirm: { name: "Sim", show: true },
                btCancel: { name: "Não", show: true },
            };
            this.$store.commit("confirmMessage/showConfirmMessage", obj);
        },
        delProfile(data) {
            let obj = {
                url: "/api/v1/title/user/profile/delete",
                query: {
                    id: data.id,
                },
                method: "post",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                let opts = {
                    message: `Perfil removido com sucesso.`,
                    type: "success",
                };
                this.$store.commit("showAlert", opts);
                let index = this.profiles.findIndex(
                    (profile) => profile.id === data.id
                );
                this.profiles.splice(index, 1);
            });
        },
        setDiscountTxt(data) {
            // console.log(data);
            let tipoDesconto = [
                "Sem desconto",
                "Valor fixo até a data informada",
                "Percentual até a data informada",
                "Desconto por dia antecipado",
            ];
            return tipoDesconto[data];
        },
        editProfile(data) {
            this.editProfileData = { ...data };
            this.registerProfileDilog = true;
        },
        toogleRegisterProfileDilog() {
            this.editProfileData = null;
            this.registerProfileDilog = !this.registerProfileDilog;
        },
    },
    data() {
        return {
            windowH: "580",
            tab: 0,
            windowH: window.innerHeight - 125,
            registerProfileDilog: false,
            editProfileData: null,
            banksProfileRegisterCbo: [],
            profiles: [],
            headers: [
                {
                    text: "Nome do perfil",
                    value: "profile_name",
                    align: "left",
                    sortable: false,
                },
                {
                    text: "Banco",
                    value: "bank.bank_name",
                    align: "left",
                    sortable: false,
                },
                {
                    text: "Pix",
                    value: "profile_data.indicadorPix",
                    align: "left",
                    sortable: false,
                },

                {
                    text: "Desconto",
                    value: "profile_data.desconto.tipo",
                    align: "left",
                    sortable: false,
                },
                {
                    text: "Ações",
                    value: "actions",
                    align: "center",
                    sortable: false,
                },
            ],
        };
    },
    mounted() {
        this.getConfiguredBanks();
        this.getProfiles();
        if (this.$route.query.showProf) {
            this.tab = 1
        }

    },
    props: {
        verifySteps: Function,
        stepsOn: Number,
        // getProfiles: Function,
        // profiles: Array,
        // banksProfileRegisterCbo: Array,
        profilesListDialog: Boolean,
        toogleProfilesListDialog: Function,
    },
    components: { ProfileRegister, ConfigBankAdm, BilletList, OperatorListCreditCard },
    computed: {
        responsiveSize() {
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return 220;
                case "sm":
                    return 400;
                case "md":
                    return 500;
                case "lg":
                    return 600;
                case "xl":
                    return 800;
            }
        },
    },
    created() {
        window.addEventListener("resize", this.getHeightPage);
    },
    destroyed() {
        window.removeEventListener("resize", this.getHeightPage);
    },
};

</script>

<style lang="scss" scoped></style>